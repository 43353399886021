import React, { useState, CSSProperties, useEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Persona, PersonaSize } from "@fluentui/react";
import * as staffUtils from "../../../shared/utils/StaffUtils";
import PhotoCallout from "./common/persona/PhotoCallout";
import { Link, NavLink } from "react-router-dom";
import { useGetUserQuery } from "../redux/slices/apis/UserApi";

const Header = () => {
  const dropDownMenuItemStyle: CSSProperties = {
    color: "black",
  };

  const headerTextStyle: CSSProperties = {
    color: "white",
    padding: "10px",
  };

  const brandStyle: CSSProperties = {
    paddingRight: 20,
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [showCallout, setShowCallout] = useState<boolean>(false);
  /*const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.userReducer
  );*/
  const { data: user } = useGetUserQuery();
  // const {isAuthenticated} = user;

  const [isTestEnv, setIsTestEnv] = useState(false);

  useEffect(() => {
    if (fdot.process.env.APP_ENVIRONMENT !== "production") {
      setIsTestEnv(true);
    }
  }, []);

  //   const externalUser = user && user?.external;
  const toggle = () => setIsExpanded(!isExpanded);

  const getLoginLink = () => {
    const loginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/login`;

    if (user && user.azureAdOid) {
      return null;
    }
    return (
      <a id="login" className="nav-link" href={loginUrl} style={headerTextStyle}>
        Log In
      </a>
    );
  };

  const getLoggedInUserDisplay = () => {
    if (user === undefined || !user.azureAdOid) {
      return null;
    }
    const nameOfStaff = staffUtils.getFirstNameLastName(user);
    const initials = staffUtils.getUserInitials(user);

    return user ? (
      <>
        {/* <div className="d-none d-xl-block" style={headerTextStyle}> */}
        <Persona
          id="LoggedInUserPhoto"
          imageUrl={user.photoUri || undefined}
          imageAlt={`Profile Pic for: ${nameOfStaff}`}
          size={PersonaSize.size40}
          text={nameOfStaff}
          styles={{ primaryText: { color: "white" } }}
          imageInitials={initials}
          imageShouldFadeIn={true}
          onClick={() => setShowCallout(true)}
        ></Persona>
        {showCallout && (
          <PhotoCallout
            personaId="LoggedInUserPhoto"
            showOptions={true}
            staff={user}
            imageUrl={user.photoUri || undefined}
            nameOfStaff={nameOfStaff}
            imageInitials={initials}
            close={() => setShowCallout(false)}
          ></PhotoCallout>
        )}
        {/* </div> */}
      </>
    ) : (
      ""
    );
  };

  const getAdminOptions = () => {
    if (user && user?.isAdmin) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            Administration
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              style={dropDownMenuItemStyle}
              tag={Link}
              to={"/Staff"}
            >
              Staff Access List
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
    return null;
  };

  const navbarStyles: CSSProperties = {
    backgroundColor: "#33557C",
  };

  const getNavbar = () => {
    return (
      <div>
        <Navbar dark expand="md" style={navbarStyles}>
          <NavbarBrand href="/" style={brandStyle}>
            <img
              src="https://webincludes.dot.state.fl.us/images/FDOTLogowhitetranssm.png"
              alt="fdot logo"
            />
            <span title="Sunex">SUNEX</span>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isExpanded} navbar>
            {/* <Nav className="me-auto" navbar>{getAdminOptions()}</Nav> */}
            {user !== undefined && user.azureAdOid && (
              <Nav navbar>
                <NavItem>
                  <NavLink
                    to={"/events"}
                    title="Events"
                    id="events"
                    className="text-white nav-link p-4"
                  >
                    Events
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to={"/notifications"}
                    title="Notifications"
                    id="notifications"
                    className="text-white nav-link p-4"
                  >
                    Notifications
                  </NavLink>
                </NavItem>
              </Nav>
            )}
            <Nav>
              <NavItem>
                <NavLink
                  to={"/help"}
                  title="Help"
                  id="help"
                  className="text-white nav-link p-4"
                >
                  Help
                </NavLink>
              </NavItem>
            </Nav>
            <Nav className="ml-auto">
              <NavItem>{getLoggedInUserDisplay()}</NavItem>
              <NavItem>{getLoginLink()}</NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {
          isTestEnv && (
            <div className="container-fluid bg-warning">
              <div className="row d-flex justify-content-center font-weight-bold">
                Warning: This is a Test Environment!
              </div>
            </div>
          )
        }
      </div >
    );
  };

  return getNavbar();
};

export default Header;
